@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: white;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --header-height: 62px;
    --bottom-nav-height: 86px;
    --chat-room-top-notice-height: 42px;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-White;
  }
}

@layer utilities {
  .flex-center {
    @apply flex justify-center items-center;
  }
  .flex-col-center {
    @apply flex flex-col justify-center items-center;
  }

  .hide-scrollbar::-webkit-scrollbar {
    @apply hidden;
  }

  .dialog-content:focus-visible,
  .dialog-content:focus-within,
  .dialog-content:focus {
    outline: none;
  }

  .promotion-bg-pollen {
    background:
      no-repeat url('/public/assets/images/promotion/pollen.png'),
      linear-gradient(180deg, #ff368a 0%, rgba(255, 76, 76, 0.88) 100%);

    background-size: cover;
    background-position: center center;
  }
}

html {
  background-color: black;
}

html,
body,
div#root {
  height: 100%;
  font-family: 'Pretendard';
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

div#root {
  display: flex;
  flex-direction: column;
}

.chat-message-bubble > a {
  color: #006dff;
  text-decoration: underline;
  text-underline-offset: 2.5px;
  word-break: break-all;
}
.chat-message-bubble > a:visited {
  color: purple;
}

.Toastify__toast-icon {
  margin-inline-end: 4px !important;
}

@media only screen and (max-width: 980px) {
  html,
  body {
    -webkit-overflow-scrolling: auto;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.highlight-default {
  background: transparent;
}

.Toastify__toast-container--top-center {
  top: 64px !important;
}

.disable-tab-highlight {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-display: swap;
  src:
    local('Pretendard Black'),
    url('./fonts/woff2/Pretendard-Black.woff2') format('woff2'),
    url('./fonts/woff/Pretendard-Black.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-display: swap;
  src:
    local('Pretendard ExtraBold'),
    url('./fonts/woff2/Pretendard-ExtraBold.woff2') format('woff2'),
    url('./fonts/woff/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src:
    local('Pretendard Bold'),
    url('./fonts/woff2/Pretendard-Bold.woff2') format('woff2'),
    url('./fonts/woff/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  src:
    local('Pretendard SemiBold'),
    url('./fonts/woff2/Pretendard-SemiBold.woff2') format('woff2'),
    url('./fonts/woff/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src:
    local('Pretendard Medium'),
    url('./fonts/woff2/Pretendard-Medium.woff2') format('woff2'),
    url('./fonts/woff/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src:
    local('Pretendard Regular'),
    url('./fonts/woff2/Pretendard-Regular.woff2') format('woff2'),
    url('./fonts/woff/Pretendard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-display: swap;
  src:
    local('Pretendard Light'),
    url('./fonts/woff2/Pretendard-Light.woff2') format('woff2'),
    url('./fonts/woff/Pretendard-Light.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-display: swap;
  src:
    local('Pretendard ExtraLight'),
    url('./fonts/woff2/Pretendard-ExtraLight.woff2') format('woff2'),
    url('./fonts/woff/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-display: swap;
  src:
    local('Pretendard Thin'),
    url('./fonts/woff2/Pretendard-Thin.woff2') format('woff2'),
    url('./fonts/woff/Pretendard-Thin.woff') format('woff');
}
